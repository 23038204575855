<template>
  <div id="app">
    <AppLayout>
      <router-view />
    </AppLayout>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import network from "./utils/network";
import { newLogger } from "@/utils/util";

const logger = newLogger("App.VUE");

export default {
  metaInfo() {
    return {
      title: "Cerise administration",
      meta: [{ name: "robots", content: "noindex,nofollow" }],
    };
  },
  components: {
    AppLayout: () => import("@/layouts/AppLayout.vue"),
  },

  async created() {
    logger.info("App created");
    network.configure(this.$store, this.$router, this.$buefy);
  },
};
</script>

<style lang="scss" rel="stylesheet/scss">
@import "~@/assets/scss/main.scss";
</style>
