import network from "../utils/network";
import Vue from "vue";

export default class StoreCore {
  constructor() {
    this.requestBuffer = {}; // buffer for ongoing promises
    this.requestCache = {}; // cache for responses
    this.cacheTiming = {};
    this.cachePruner = setInterval(() => {
      let now = new Date();
      for (let opId in this.cacheTiming) {
        if (now - this.cacheTiming[opId] > 5 * 60 * 1000) {
          delete this.cacheTiming[opId];
          delete this.requestCache[opId];
        }
      }
    }, 30 * 1000);
  }

  clearCoreCache() {
    this.requestCache = {};
    this.requestBuffer = {};
  }

  async makeRequest({
    method = "get",
    url,
    data,
    cacheable = true,
    dispatch,
    flair,
    timeout,
    force = false,
    event = false,
    messages = {
      progress: "Operation in progress",
      fail: "Unsuccessfull operation",
      success: "Successfull operation",
    },
    errorToast = true,
  }) {
    return await this.remoteRequest(
      method,
      url,
      data,
      cacheable,
      dispatch,
      flair,
      timeout,
      force,
      event,
      messages,
      errorToast
    );
  }

  async remoteRequest(
    reqType,
    url,
    params,
    cacheable,
    dispatch,
    flair,
    timeout,
    force,
    event = false,
    messages = {
      progress: "Operation in progress",
      fail: "Unsuccessfull operation",
      success: "Successfull operation",
    },
    errorToast = false
  ) {
    // normalizing
    reqType = reqType.toLowerCase();
    let operationId = url;
    if (params) {
      let paramsString;
      if (
        params.params &&
        URLSearchParams.prototype.isPrototypeOf(params.params)
      ) {
        paramsString = params.params.toString();
      } else {
        paramsString = JSON.stringify(params);
      }
      operationId = operationId + "_" + paramsString;
    }

    if (reqType === "get" && this.requestBuffer[operationId]) {
      // the request is already executing, ignore, return earlier promise
      return this.requestBuffer[operationId];
    }
    cacheable = cacheable && reqType === "get"; // only cache GET
    if (cacheable && !force && !!this.requestCache[operationId]) {
      return this.requestCache[operationId];
    }

    let promise = async () => {
      let reqId = this.generateRequestId();
      this.startLoading(reqId, reqType, url, flair, dispatch, event, messages);
      let axios = await network.connection();
      if (timeout) {
        axios.defaults.timeout = timeout;
      }
      try {
        let response = await axios[reqType](url, params);
        this.endLoading(reqId, dispatch, true, event);
        this.requestCache[operationId] = response.data;
        this.cacheTiming[operationId] = new Date();
        this.requestBuffer[operationId] = null;

        return this.requestCache[operationId];
      } catch (error) {
        console.error(error);
        if (
          error.response.status === 409 &&
          error.response.data &&
          "object" === typeof error.response.data &&
          "DUPLICATE_ENTRY" === error.response.data.type &&
          errorToast
        ) {
          Vue.prototype.$buefy.toast.open({
            duration: 5000,
            message: `A(z) ${error.response.data.constraintName} mező értéke már foglalt!`,
            position: "is-bottom",
            type: "is-danger",
          });
        } else if (errorToast && error.response.status === 503) {
          Vue.prototype.$buefy.toast.open({
            duration: 5000,
            message: `<strong>(503)</strong> - Service unavailable, try again later`,
            position: "is-bottom",
            type: "is-danger",
          });
        } else if (errorToast && error.response.status === 400) {
          Vue.prototype.$buefy.toast.open({
            duration: 5000,
            message: error.response.data.message,
            position: "is-bottom",
            type: "is-danger",
          });
        } else if (
          errorToast &&
          error.response.status !== 401 &&
          error.response.status !== 403
        ) {
          Vue.prototype.$buefy.toast.open({
            duration: 5000,
            message: `<strong>(500)</strong> - Internal server error, try again later`,
            position: "is-bottom",
            type: "is-danger",
          });
        }
        this.endLoading(reqId, dispatch, false, event);
        this.requestBuffer[operationId] = null;

        throw error;
      }
    };
    this.requestBuffer[operationId] = promise.call(this);
    return this.requestBuffer[operationId];
  }

  generateRequestId() {
    return Math.floor(Math.random() * 100000);
  }

  startLoading(reqId, reqType, url, flair, dispatch, event = false, messages) {
    // dispatch(
    //   "loading/startLoading",
    //   {
    //     id: reqId,
    //     type: reqType,
    //     url: url,
    //     flair: flair,
    //     event: event,
    //     messages: messages,
    //   },
    //   { root: true }
    // );
  }

  endLoading(reqId, dispatch, result, event = false) {
    // dispatch(
    //   "loading/finishLoading",
    //   { id: reqId, result: result, event: event },
    //   { root: true }
    // );
  }
}
