import { newLogger } from "@/utils/util";
import Vue from "vue";
import StoreCore from "@/store/StoreCore";

let logger = newLogger("Permission-Store");

const urls = {
  get: (id) => `/permission/${id}`,
  all: "/permission",
};

const initState = () => {
  return {
    permissions: [],
    permission: {},
  };
};

const storeCore = new StoreCore();
const actions = {
  fetch: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.all,
        dispatch,
        flair: "Loading permissions...",
        force,
      });
      commit("savePermissions", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  get: async function ({ commit, dispatch }, { permissionId, force } = {}) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.get(permissionId),
        dispatch,
        flair: "Loading permission...",
        force,
      });
      commit("savePermission", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  savePermission: (state, data) => {
    Vue.set(state, "permission", data);
  },
  savePermissions: (state, data) => {
    Vue.set(state, "permissions", data);
  },
};

const getters = {
  permission: (state) => {
    return state.permission;
  },
  permissions: (state) => {
    return state.permissions;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
