import { newLogger } from "@/utils/util";
import Vue from "vue";
import StoreCore from "@/store/StoreCore";

let logger = newLogger("LocationType-Store");

const urls = {
  get: (id) => `/locationType/${id}`,
  all: "/locationType",
};

const initState = () => {
  return {
    locationTypes: [],
    locationType: {},
  };
};

const storeCore = new StoreCore();
const actions = {
  fetch: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.all,
        dispatch,
        flair: "Loading location types...",
        force,
      });
      commit("saveLocationTypes", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  get: async function ({ commit, dispatch }, { locationTypeCode, force } = {}) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.get(locationTypeCode),
        dispatch,
        flair: "Loading location type...",
        force,
      });
      commit("saveLocationType", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveLocationType: (state, data) => {
    Vue.set(state, "locationType", data);
  },
  saveLocationTypes: (state, data) => {
    Vue.set(state, "locationTypes", data);
  },
};

const getters = {
  locationType: (state) => {
    return state.locationType ?? {};
  },
  locationTypes: (state) => {
    return state.locationTypes ?? [];
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
