import session from "./session";
import location from "./location";
import price from "./price";
import user from "./user";
import role from "./role";
import country from "./country";
import currency from "./currency";
import locationType from "./locationType";
import vendor from "./vendor";
import permission from "./permission";
import certification from "./certification";
import weightUnit from "./weightUnit";
import outputQuality from "./outputQuality";
import cherryQuality from "./cherryQuality";
import processingType from "./processingType";
import season from "./season";

/**
 * Gathering all modules to export into the router
 */
export default {
  session,
  location,
  price,
  user,
  role,
  country,
  currency,
  locationType,
  vendor,
  permission,
  certification,
  weightUnit,
  processingType,
  outputQuality,
  cherryQuality,
  season,
};
