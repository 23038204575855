import { newLogger } from "@/utils/util";
import Vue from "vue";
import StoreCore from "@/store/StoreCore";
import network from "@/utils/network";

let logger = newLogger("Location-Store");

const urls = {
  locations: "/location",
  get: (id) => `/location/${id}`,
  archive: "/location/archive",
  reactivate: "/location/reactivate",
  create: "/location",
  update: (id) => `/location/${id}`,
};

/**
 * Description of the store
 */

const initState = () => {
  return {
    locations: [],
    location: {},
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchAll: async function ({ commit, dispatch }, { params, force } = {}) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.locations,
        data: params,
        dispatch,
        flair: "Loading locations...",
        force,
      });
      commit("saveLocations", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),

  get: async function ({ commit, dispatch }, { locationId, force } = {}) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.get(locationId),
        dispatch,
        flair: "Loading location...",
        force,
      });
      commit("saveLocation", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  create: async function ({ commit, dispatch }, location) {
    try {
      const axios = await network.formDataConnection();
      await axios.post(urls.create, location);
    } catch (err) {
      logger.error(err);
    }
  },
  update: async function ({ commit, dispatch }, location) {
    try {
      const axios = await network.formDataConnection();
      await axios.put(urls.update(location.get("id")), location);
    } catch (err) {
      logger.error(err);
    }
  },
  archive: async function ({ commit, dispatch }, locations) {
    try {
      await this.makeRequest({
        method: "post",
        url: urls.archive,
        data: locations,
        dispatch,
        flair: "Archiving users...",
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  reactivate: async function ({ commit, dispatch }, users) {
    try {
      await this.makeRequest({
        method: "post",
        url: urls.reactivate,
        data: users,
        dispatch,
        flair: "Reactivating users...",
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveLocations: (state, data) => {
    Vue.set(state, "locations", data);
  },
  saveLocation: (state, data) => {
    Vue.set(state, "location", data);
  },
};

const getters = {
  locations: (state) => {
    return state.locations;
  },
  wetLocations: (state) => {
    return state.locations.filter(
      (location) => location.locationTypeCode === "wetmill"
    );
  },
  archivedLocation: (state) => {
    return state.locations.filter((location) => location.deletedAt !== null);
  },
  activeLocation: (state) => {
    return state.locations.filter((location) => location.deletedAt === null);
  },

  location: (state) => {
    return state.location;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
