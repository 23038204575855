import { newLogger } from "@/utils/util";
import axios from "axios";
import Vue from "vue";
import jwtDecode from "jwt-decode";
import network from "@/utils/network";
import store from "../../index";
import { UNKNOWN, UNKNOWN_USER } from "@/utils/const";

const JWT_STORAGE_KEY = "cerise_jwt";

// Private variables
const endpoints = {
  login: "/api/bulwark/auth/admin/login",
  logout: "/bulwark/auth/logout",
  recovery: "/api/bulwark/password/recover",
};
const claims = {
  grantedAuthorities: "ga",
  refreshToken: "rfsht",
  refreshGeneration: "rfshg",
  expiry: "exp",
};
let logger = newLogger("SessionControl");

// Private methods
const parseJwt = (token) => {
  // src: https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript
  if (!token) return null;
  let jwt = jwtDecode(token);
  jwt.authorities = !!jwt[[claims.grantedAuthorities]]
    ? jwt[claims.grantedAuthorities].split(",")
    : [];
  return jwt;
};

/**
 * The session is considered logged in (fully) if the JWT is present and not expired
 */
const isLoggedIn = (jsonJwt) => {
  let now = Date.now();
  return !!jsonJwt && now < jsonJwt[claims.expiry] * 1000;
};

/**
 * The session is "ready" and considered (roughly) to be loggedIn if the JWT is present and has a refresh-token
 * or if the JWT is not expired
 */
const isReady = (jsonJwt) => {
  return isLoggedIn(jsonJwt) || (!!jsonJwt && !!jsonJwt[claims.refreshToken]);
};

/**
 * Inits the session state from the JWT stored in LocalStorage
 * If the JWT cannot be considered "ready" it is not loaded
 */
const initState = () => {
  let savedJwt = localStorage.getItem(JWT_STORAGE_KEY);
  let jsonJwt = parseJwt(savedJwt);
  let ready = isReady(jsonJwt);
  if (!ready) {
    logger.debug("No usable JWT detected on token: " + savedJwt);
    jsonJwt = {};
    savedJwt = ""; // reset the savedJwt
  } else {
    logger.debug("Usable JWT detected, considering session ready!");
  }
  return {
    encodedJwt: savedJwt,
    jsonJwt: jsonJwt,
    loginRedirect: "/",
    isReady: ready,
    reloginRequired: false,
  };
};

const actions = {
  refreshJwt: async (context, jwt) => {
    if (jwt) {
      try {
        let jsonJwt = parseJwt(jwt);
        context.commit("login", { encodedJwt: jwt, jsonJwt: jsonJwt });
      } catch (error) {
        throw "Unable to parse given JWT!";
      }
    } else {
      throw "No JWT in Login response!";
    }
  },
  login: async ({ commit }, loginRequest) => {
    try {
      let response = await axios.post(endpoints.login, loginRequest);
      let token = response.headers["x-auth-token"];
      if (token) {
        try {
          let jsonJwt = parseJwt(token);
          commit("login", { encodedJwt: token, jsonJwt: jsonJwt });
          logger.info("Login successful!");
        } catch (error) {
          throw "Unable to parse given JWT!";
        }
      } else {
        throw "No JWT in Login response!";
      }
      return Promise.resolve(true);
    } catch (e) {
      logger.warn("Error on login");
      logger.warn(e);
      return Promise.reject((e.response && e.response.data.message) || e);
    }
  },
  sendRecoveryEmail: async (context, username) => {
    try {
      await axios.post(endpoints.recovery + "/email", { username });
      logger.debug(`Sending recovery email for ${username}...`);
      return Promise.resolve(true);
    } catch (e) {
      logger.error(e);
      return Promise.reject((e.response && e.response.data.message) || e);
    }
  },
  recoverPassword: async (context, data) => {
    try {
      await axios.post(endpoints.recovery, data);
      logger.debug(`Recovering password...`);
      return Promise.resolve(true);
    } catch (e) {
      logger.error(e);
      return Promise.reject((e.response && e.response.data.message) || e);
    }
  },
  logout: async (context) => {
    try {
      const axios = await network.connection();
      logger.debug(`Logging out...`);
      let response = await axios.post(endpoints.logout);
      context.commit("logout", response.data);
    } catch (err) {
      logger.error(err);
    }
  },
  boot: (context) => {
    context.commit("logout", true);
  },
};

const mutations = {
  login: (state, { encodedJwt, jsonJwt }) => {
    Vue.set(state, "encodedJwt", encodedJwt);
    Vue.set(state, "jsonJwt", jsonJwt);
    Vue.set(state, "isReady", true);
    localStorage.setItem(JWT_STORAGE_KEY, encodedJwt);
  },
  logout: async (state, response) => {
    Vue.set(state, "encodedJwt", "");
    Vue.set(state, "jsonJwt", {});
    Vue.set(state, "isReady", false);

    localStorage.setItem(JWT_STORAGE_KEY, "");

    const promises = [];

    promises.push(store.dispatch("certification/clearCache"));
    promises.push(store.dispatch("cherryQuality/clearCache"));
    promises.push(store.dispatch("country/clearCache"));
    promises.push(store.dispatch("currency/clearCache"));
    promises.push(store.dispatch("location/clearCache"));
    promises.push(store.dispatch("locationType/clearCache"));
    promises.push(store.dispatch("outputQuality/clearCache"));
    promises.push(store.dispatch("permission/clearCache"));
    promises.push(store.dispatch("price/clearCache"));
    promises.push(store.dispatch("role/clearCache"));
    promises.push(store.dispatch("user/clearCache"));
    promises.push(store.dispatch("vendor/clearCache"));
    promises.push(store.dispatch("weightUnit/clearCache"));
    promises.push(store.dispatch("season/clearCache"));

    await Promise.all(promises);
  },
  relogin: (state) => {
    Vue.set(state, "reloginRequired", true);
  },
  liftRelogin: (state) => {
    Vue.set(state, "reloginRequired", false);
  },
  redirect: (state, url) => {
    state.loginRedirect = url;
  },
};

const getters = {
  axios: (state) => {
    return state.axios;
  },
  isLoggedIn: (state) => {
    return isLoggedIn(state.jsonJwt);
  },
  isReady: (state) => {
    return state.isReady;
  },
  jwt: (state) => {
    return state.encodedJwt;
  },
  userId: (state) => {
    if (isReady(state.jsonJwt)) {
      return state.jsonJwt.sub;
    } else {
      return UNKNOWN;
    }
  },
  displayName: (state) => {
    if (isReady(state.jsonJwt)) {
      return state.jsonJwt.fname;
    } else {
      return UNKNOWN_USER;
    }
  },
  refreshToken: (state) => {
    if (isReady(state.jsonJwt)) {
      return state.jsonJwt.rfshg;
    } else {
      return undefined;
    }
  },
  authorities: (state) => {
    if (isReady(state.jsonJwt)) {
      return state.jsonJwt.authorities;
    } else {
      return [];
    }
  },
  hasAuthority: (state) => (auth) => {
    if (!auth) return true;
    let hasAuthority = false;
    if (state.jsonJwt.authorities) {
      hasAuthority = state.jsonJwt.authorities.includes(auth);
    }
    return hasAuthority;
  },
  hasAnyAuthority:
    (state) =>
    (...auths) => {
      let hasAuthority = false;
      if (state.jsonJwt.authorities) {
        hasAuthority = state.jsonJwt.authorities.some((role) =>
          auths.includes(role)
        );
      }
      return hasAuthority;
    },
  jwtHeader: (state) => {
    return { "X-Auth-Token": state.encodedJwt };
  },
  reloginRequired: (state) => {
    return state.reloginRequired;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
