import { newLogger } from "@/utils/util";
import Vue from "vue";
import StoreCore from "@/store/StoreCore";

let logger = newLogger("OutputQuality-Store");

const urls = {
  get: (id) => `/outputQuality/${id}`,
  all: "/outputQuality",
  delete: (id) => `/outputQuality/${id}`,
  create: "/outputQuality",
  update: (id) => `/outputQuality/${id}`,
};

const initState = () => {
  return {
    outputQualities: [],
    outputQuality: {},
  };
};

const storeCore = new StoreCore();
const actions = {
  fetch: async function ({ commit, dispatch }, { withDeleted, force } = {}) {
    try {
      const url = urls.all + (withDeleted ? "?withDeleted=true" : "");
      let data = await this.makeRequest({
        method: "get",
        url,
        dispatch,
        flair: "Loading outputQualities...",
        force,
      });
      commit("saveOutputQualities", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  get: async function ({ commit, dispatch }, { outputQualityId, force } = {}) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.get(outputQualityId),
        dispatch,
        flair: "Loading outputQuality...",
        force,
      });
      commit("saveOutputQuality", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  create: async function ({ commit, dispatch }, request) {
    try {
      await this.makeRequest({
        method: "post",
        url: urls.create,
        data: request,
        dispatch,
        flair: "Saving outputQuality...",
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  update: async function ({ commit, dispatch }, { id, request }) {
    try {
      await this.makeRequest({
        method: "put",
        url: urls.update(id),
        data: request,
        dispatch,
        flair: "Updating outputQuality...",
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  delete: async function ({ commit, dispatch }, id) {
    try {
      await this.makeRequest({
        method: "delete",
        url: urls.delete(id),
        dispatch,
        flair: "Deleting outputQuality...",
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveOutputQuality: (state, data) => {
    Vue.set(state, "outputQuality", data);
  },
  saveOutputQualities: (state, data) => {
    Vue.set(state, "outputQualities", data);
  },
};

const getters = {
  outputQuality: (state) => {
    return state.outputQuality;
  },
  outputQualities: (state) => {
    return state.outputQualities;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
