import { newLogger } from "@/utils/util";
import Vue from "vue";
import StoreCore from "@/store/StoreCore";

let logger = newLogger("Currency-Store");

const urls = {
  get: (id) => `/currency/${id}`,
  all: "/currency",
  delete: (id) => `/currency/${id}`,
  create: "/currency",
  update: (id) => `/currency/${id}`,
};

const initState = () => {
  return {
    currencies: [],
    currency: {},
  };
};

const storeCore = new StoreCore();
const actions = {
  fetch: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.all,
        dispatch,
        flair: "Loading currencies...",
        force,
      });
      commit("saveCurrencies", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  get: async function ({ commit, dispatch }, { currencyCode, force } = {}) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.get(currencyCode),
        dispatch,
        flair: "Loading currency...",
        force,
      });
      commit("saveCurrency", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  create: async function ({ commit, dispatch }, request) {
    try {
      await this.makeRequest({
        method: "post",
        url: urls.create,
        data: request,
        dispatch,
        flair: "Saving currency...",
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  update: async function ({ commit, dispatch }, { id, request }) {
    try {
      await this.makeRequest({
        method: "put",
        url: urls.update(id),
        data: request,
        dispatch,
        flair: "Updating currency...",
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  delete: async function ({ commit, dispatch }, id) {
    try {
      await this.makeRequest({
        method: "delete",
        url: urls.delete(id),
        dispatch,
        flair: "Deleting currency...",
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveCurrency: (state, data) => {
    Vue.set(state, "currency", data);
  },
  saveCurrencies: (state, data) => {
    Vue.set(state, "currencies", data);
  },
};

const getters = {
  currency: (state) => {
    return state.currency ?? {};
  },
  currencies: (state) => {
    return state.currencies ?? [];
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
