import Vue from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";
import Buefy from "buefy";
import VueMeta from "vue-meta";
// import "buefy/dist/buefy.css";
import VueMask from "v-mask";
import VueApexCharts from "vue-apexcharts";

Vue.prototype.gapi = window.gapi;
Vue.config.productionTip = false;

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fas);
library.add(far);
Vue.component("vue-fontawesome", FontAwesomeIcon);

Vue.use(Buefy, {
  defaultToastPosition: "is-bottom",
  defaultSnackbarPosition: "is-bottom",
  defaultIconComponent: "vue-fontawesome",
  defaultIconPack: "fa",
  defaultStatusIcon: false,
  defaultModalCanCancel: ["escape", "outside", "button"],
  customIconPacks: {
    fas: {
      sizes: {
        default: "lg",
        "is-small": "1x",
        "is-medium": "2x",
        "is-large": "3x",
      },
      iconPrefix: "",
    },
  },
});

Vue.use(VueMeta);
Vue.use(VueMask);

Vue.filter("phone", function (phone) {
  return phone
    .replace(/[^0-9]/g, "")
    .replace(/(\d{2})(\d{2})(\d{3})(\d{4})/, "+$1 $2 $3 $4");
});

Vue.filter("empty", function (data) {
  if (data === 0) {
    return 0;
  }
  return data ? data : "-";
});

Vue.filter("lengthy", function (data) {
  if (data != null) {
    if (data.length > 20) {
      return data.substring(0, 20).concat("...");
    }
    return data;
  }
  return "-";
});
Vue.filter("booleanText", function (data) {
  if (data != null) {
    if (data == true) {
      return "Igen";
    } else if (data == false) {
      return "Nem";
    }
  }
  return "-";
});

Vue.filter("thousand", function (data) {
  return data !== undefined && data !== null
    ? Number(data).toLocaleString()
    : "-";
});

Vue.filter("dateTime", function (data) {
  return data ? formatDateTime(data) : "-";
});

Vue.filter("date", function (data) {
  return data ? formatDate(data) : "-";
});

Vue.filter("monify", function (data) {
  if (!data) {
    return "-";
  }
  return formatMoney(data);
});

Vue.filter("percentage", function (data) {
  return data ? data + " %" : "-";
});

Vue.filter("percentify", function (data) {
  if (Number.isNaN(data) || data === "NaN") return "-";
  if (!Number.isFinite(data) || data === "Infinity") return "∞";
  return (data * 100).toFixed(2) + "%";
});

Vue.filter("number", function (data) {
  if (isNullOrUndefined(data) || Number.isNaN(data) || data === "NaN")
    return "-";
  if (!Number.isFinite(data) || data === "Infinity") return "∞";
  return data;
});

import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import {
  formatDate,
  formatDateTime,
  formatMoney,
  isNullOrUndefined,
} from "./utils/util";
import {
  required,
  size,
  email,
  regex,
  integer,
  double,
  max_value,
  min_value,
  numeric,
  min,
} from "vee-validate/dist/rules";

extend("required", required);

extend("size", size);

extend("email", email);

extend("regex", regex);

extend("numeric", numeric);

extend("integer", integer);

extend("double", double);

extend("max_value", max_value);

extend("min_value", min_value);

extend("min", min);

extend("passwords-match", (value, params) => {
  if (value === params[0]) return true;
  return "Entered passwords mismatch.";
});

extend("low_price", {
  ...max_value,
  message: "The low price must be less than the high price.",
});

extend("high_price", {
  ...min_value,
  message: "The high price must be more than the low price.",
});

extend("password_length", (value, params) => {
  if (value.length >= params[0]) return true;
  return `The password must be at least ${params[0]} characters long.`;
});

// Register it globally
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: "maps",
  },
});

Vue.use(VueApexCharts);
Vue.component("ApexChart", VueApexCharts);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
