import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules,
});

/**
 * Exporting the primed Store here
 */
export default store;
