import Vue from "vue";
import VueRouter from "vue-router";
import vuex from "@/store/index";

Vue.use(VueRouter);

const routes = [
  // Main views
  {
    path: "",
    name: "HomePage",
    component: () => import("@/views/HomePage.vue"),
    meta: {
      protected: true,
    },
  },
  {
    path: "/login",
    name: "LoginPage",
    props: true,
    component: () => import("@/views/LoginPage.vue"),
    meta: {
      layout: "AppLayoutLogin",
      protected: false,
    },
  },
  {
    path: "/password-recovery",
    name: "PasswordRecoveryPage",
    props: true,
    component: () => import("@/views/PasswordRecoveryPage.vue"),
    meta: {
      layout: "AppLayoutLogin",
      protected: false,
    },
  },
  {
    path: "/ui-kit",
    name: "UIKitPage",
    props: true,
    component: () => import("@/views/UIKitPage.vue"),
    meta: {
      protected: false,
    },
  },
  {
    path: "/set-price",
    name: "SetLocationPricePage",
    props: true,
    component: () => import("@/views/SetLocationPricePage.vue"),
    meta: {
      protected: true,
      breadcrumbs: [
        { text: "Home", name: "HomePage" },
        { text: "Set price", name: "SetLocationPricePage" },
      ],
    },
  },
  {
    path: "/staff",
    name: "StaffListPage",
    props: true,
    component: () => import("@/views/StaffListPage.vue"),
    meta: {
      protected: true,
      breadcrumbs: [
        { text: "Home", name: "HomePage" },
        { text: "Staff", name: "StaffListPage" },
      ],
    },
  },
  {
    path: "/vendor",
    name: "VendorListPage",
    props: true,
    component: () => import("@/views/VendorListPage.vue"),
    meta: {
      protected: true,
      breadcrumbs: [
        { text: "Home", name: "HomePage" },
        { text: "Vendors", name: "VendorListPage" },
      ],
    },
  },
  {
    path: "/vendor/:id",
    name: "VendorDashboard",
    props: true,
    component: () => import("@/components/VendorDashboard.vue"),
    meta: {
      protected: true,
      breadcrumbs: [
        { text: "Home", name: "HomePage" },
        { text: "Vendor profile", name: "VendorDashboard" },
      ],
    },
  },
  {
    path: "/users",
    name: "UserListPage",
    props: true,
    component: () => import("@/views/UserListPage.vue"),
    meta: {
      protected: true,
      breadcrumbs: [
        { text: "Home", name: "HomePage" },
        { text: "Users", name: "UserListPage" },
      ],
    },
  },
  {
    path: "/location-tab",
    name: "LocationTabPage",
    props: true,
    component: () => import("@/views/LocationTabPage.vue"),
    meta: {
      protected: true,
      breadcrumbs: [
        { text: "Home", name: "HomePage" },
        { text: "Locations", name: "LocationTabPage" },
      ],
    },
  },
  {
    path: "/processing-types",
    name: "ProcessingTypesPage",
    props: true,
    component: () => import("@/views/ProcessingTypesPage.vue"),
    meta: {
      protected: true,
      breadcrumbs: [
        { text: "Home", name: "HomePage" },
        { text: "Processing types", name: "ProcessingTypesPage" },
      ],
    },
  },
  {
    path: "/settings",
    name: "SettingsPage",
    component: () => import("@/views/SettingsPage.vue"),
    meta: {
      protected: true,
      breadcrumbs: [
        { text: "Home", name: "HomePage" },
        { text: "Settings", name: "SettingsPage" },
      ],
    },
  },
  {
    path: "/location-detail-tab/:locationId",
    name: "LocationDetailTabPage",
    props: true,
    component: () => import("@/views/LocationDetailTabPage.vue"),
    meta: {
      protected: true,
      breadcrumbs: [
        { text: "Home", name: "HomePage" },
        { text: "Locations", name: "LocationTabPage" },
        { text: "Location profile", name: "LocationDetailTabPage" },
      ],
    },
  },
  {
    path: "/location-process-status",
    name: "LocationProcessStatus",
    props: true,
    component: () => import("@/components/LocationProcessStatus.vue"),
    meta: {
      protected: true,
      breadcrumbs: [
        { text: "Home", name: "HomePage" },
        { text: "Location process statuses", name: "LocationProcessStatus" },
      ],
    },
  },
  {
    path: "/location/:locationId",
    name: "LocationProfilePage",
    props: true,
    component: () => import("@/views/LocationProfilePage.vue"),
    meta: {
      protected: true,
      breadcrumbs: [
        { text: "Home", name: "HomePage" },
        { text: "Locations", name: "LocationTabPage" },
        { text: "Location profile", name: "LocationDetailTabPage" },
      ],
    },
  },
  {
    path: "/user/:userId",
    name: "UserProfilePage",
    props: true,
    component: () => import("@/views/UserProfilePage.vue"),
    meta: {
      protected: true,
      breadcrumbs: [
        { text: "Home", name: "HomePage" },
        { text: "Users", name: "UserListPage" },
        { text: "Profile", name: "UserProfilePage" },
      ],
    },
  },
  {
    path: "/user-edit",
    name: "NewUserPage",
    props: true,
    component: () => import("@/views/UserEditPage.vue"),
    meta: {
      protected: true,
      breadcrumbs: [
        { text: "Home", name: "HomePage" },
        { text: "Users", name: "UserListPage" },
        { text: "New user", name: "NewUserPage" },
      ],
    },
  },
  {
    path: "/user-edit/:userId",
    name: "UserEditPage",
    props: true,
    component: () => import("@/views/UserEditPage.vue"),
    meta: {
      protected: true,
      breadcrumbs: [
        { text: "Home", name: "HomePage" },
        { text: "Users", name: "UserListPage" },
        { text: "Profile", name: "UserProfilePage" },
        { text: "User edit", name: "UserEditPage" },
      ],
    },
  },
  {
    path: "/location-edit",
    name: "NewLocationPage",
    props: true,
    component: () => import("@/views/LocationEditPage.vue"),
    meta: {
      protected: true,
      breadcrumbs: [
        { text: "Home", name: "HomePage" },
        { text: "Locations", name: "LocationTabPage" },
        { text: "New location", name: "NewLocationPage" },
      ],
    },
  },
  {
    path: "/location-edit/:userId",
    name: "LocationEditPage",
    props: true,
    component: () => import("@/views/LocationEditPage.vue"),
    meta: {
      protected: true,
      breadcrumbs: [
        { text: "Home", name: "HomePage" },
        { text: "Locations", name: "LocationTabPage" },
        { text: "Location profile", name: "LocationDetailTabPage" },
        { text: "Location edit", name: "LocationEditPage" },
      ],
    },
  },
  {
    path: "/set-price/:locationId/:qualityCode",
    name: "SetLocationPriceScreen",
    props: true,
    component: () => import("@/components/SetLocationPriceScreen.vue"),
    meta: {
      protected: true,
      breadcrumbs: [
        { text: "Home", name: "HomePage" },
        { text: "Set price", name: "SetLocationPricePage" },
        { text: "Edit price", name: "SetLocationPriceScreen" },
      ],
    },
  },
  {
    path: "/season-edit/:locationId",
    name: "NewSeasonPage",
    props: true,
    component: () => import("@/views/SeasonEditPage.vue"),
    meta: {
      protected: true,
      breadcrumbs: [
        { text: "Home", name: "HomePage" },
        { text: "Locations", name: "LocationTabPage" },
        { text: "Location profile", name: "LocationDetailTabPage" },
        { text: "New season", name: "NewSeasonPage" },
      ],
    },
  },
  {
    path: "/season-edit/:locationId/:seasonId",
    name: "SeasonEditPage",
    props: true,
    component: () => import("@/views/SeasonEditPage.vue"),
    meta: {
      protected: true,
      breadcrumbs: [
        { text: "Home", name: "HomePage" },
        { text: "Locations", name: "LocationTabPage" },
        { text: "Location profile", name: "LocationDetailTabPage" },
        { text: "Edit season", name: "SeasonEditPage" },
      ],
    },
  },
  {
    path: "/calculate-price/:locationId/:qualityCode/",
    name: "CalculateLocationPriceScreen",
    props: true,
    component: () => import("@/components/CalculateLocationPriceScreen.vue"),
    meta: {
      protected: true,
      breadcrumbs: [
        { text: "Home", name: "HomePage" },
        { text: "Set price", name: "SetLocationPricePage" },
        { text: "Edit price", name: "SetLocationPriceScreen" },
        { text: "Calculate price", name: "CalculateLocationPriceScreen" },
      ],
    },
  },
  {
    path: "/object-value/:object/",
    name: "SetObjectValueScreen",
    props: true,
    component: () => import("@/components/SetObjectValueScreen.vue"),
    meta: {
      protected: true,
      breadcrumbs: [
        { text: "Home", name: "HomePage" },
        { text: "Object value", name: "SetObjectValueScreen" },
      ],
    },
  },
  {
    path: "/reports",
    name: "Reports",
    props: true,
    component: () => import("@/views/ReportsPage.vue"),
    meta: {
      protected: true,
      breadcrumbs: [
        { text: "Home", name: "HomePage" },
        { text: "Reports", name: "Reports" },
      ],
    },
  },
  {
    path: "/reports/purchasing",
    name: "PurchaseReports",
    props: true,
    component: () => import("@/components/PurchaseReports.vue"),
    meta: {
      protected: true,
      breadcrumbs: [
        { text: "Home", name: "HomePage" },
        { text: "Reports", name: "Reports" },
        { text: "Purchase", name: "PurchaseReports" },
      ],
    },
  },
  {
    path: "/reports/processing",
    name: "ProcessReports",
    props: true,
    component: () => import("@/components/ProcessReports.vue"),
    meta: {
      protected: true,
      breadcrumbs: [
        { text: "Home", name: "HomePage" },
        { text: "Reports", name: "Reports" },
        { text: "Processing", name: "ProcessReports" },
      ],
    },
  },
  {
    path: "/*",
    name: "OtherPage",
    props: true,
    component: () => import("@/views/OtherPage.vue"),
    meta: {
      protected: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  console.debug("Routing to " + to.name);
  let isLoggedIn = vuex.getters["session/isReady"];
  if (to.meta && to.meta.protected === false) {
    if (isLoggedIn) {
      next({ name: "HomePage" });
    } else {
      next();
    }
  } else {
    if (isLoggedIn && !to.meta?.authorities) {
      next();
    } else if (
      isLoggedIn &&
      to.meta?.authorities &&
      vuex.getters["session/hasAnyAuthority"](...to.meta.authorities)
    ) {
      next();
    } else {
      console.debug("Not logged in, redirecting to login page");
      next({ name: "LoginPage", query: { redirect: to.fullPath } });
    }
  }
});
export default router;
