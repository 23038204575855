import { newLogger } from "@/utils/util";
import Vue from "vue";
import StoreCore from "@/store/StoreCore";

let logger = newLogger("Vendor-Store");

const urls = {
  list: `/vendor/list`,
};

/**
 * Description of the store
 */

const initState = () => {
  return {
    list: {},
  };
};

const storeCore = new StoreCore();

const actions = {
  list: async function ({ commit, dispatch }, { params, force } = {}) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.list,
        params,
        true,
        dispatch,
        "Loading vendors...",
        null,
        force
      );
      commit("saveList", { data });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
};

const mutations = {
  saveList: (state, data) => {
    Vue.set(state.list, "all", data.data);
  },
};

const getters = {
  list: (state) => {
    return state.list["all"];
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
