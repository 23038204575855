import { newLogger } from "@/utils/util";
import network from "@/utils/network";
import Vue from "vue";
import StoreCore from "@/store/StoreCore";

let logger = newLogger("User-Store");

const urls = {
  find: (search) => `/administration/user/find?searchText=${search}`,
  list: "/administration/user/list",
  get: (userId) => `/administration/user/${userId}`,
  archive: "/administration/user/archive",
  reactivate: "/administration/user/reactivate",
  create: "/administration/user",
  update: (userId) => `/administration/user/${userId}`,
};

/**
 * Description of the store
 */

const initState = () => {
  return {
    list: {},
    users: [],
    user: {},
  };
};

const storeCore = new StoreCore();
const actions = {
  find: async function ({ commit, dispatch }, { search = "", force } = {}) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.find(search),
        dispatch,
        flair: "Loading users...",
        force,
      });
      commit("saveUsers", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),

  list: async function ({ commit, dispatch }, { params, force } = {}) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.list,
        data: params,
        dispatch,
        flair: "Loading users...",
        force,
      });
      commit("saveList", { params, data });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  get: async function ({ commit, dispatch }, { userId, force } = {}) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.get(userId),
        dispatch,
        flair: "Loading user...",
        force,
      });
      commit("saveUser", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  archive: async function ({ commit, dispatch }, users) {
    try {
      await this.makeRequest({
        method: "post",
        url: urls.archive,
        data: users,
        dispatch,
        flair: "Archiving users...",
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      return Promise.reject(err);
    }
  }.bind(storeCore),
  reactivate: async function ({ commit, dispatch }, users) {
    try {
      await this.makeRequest({
        method: "post",
        url: urls.reactivate,
        data: users,
        dispatch,
        flair: "Reactivating users...",
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      return Promise.reject(err);
    }
  }.bind(storeCore),
  create: async function ({ commit, dispatch }, user) {
    try {
      const axios = await network.formDataConnection();
      await axios.post(urls.create, user);
    } catch (err) {
      logger.error(err);
    }
  },
  update: async function ({ commit, dispatch }, user) {
    try {
      const axios = await network.formDataConnection();
      await axios.put(urls.update(user.get("id")), user);
    } catch (err) {
      logger.error(err);
    }
  },
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveUsers: (state, data) => {
    Vue.set(state, "users", data);
  },
  saveList: (state, { params, data }) => {
    Vue.set(
      state.list,
      params?.params?.get("archived") === "true" ? "archived" : "all",
      data
    );
  },
  saveUser: (state, data) => {
    Vue.set(state, "user", data);
  },
};

const getters = {
  list: (state) => (archived) => {
    return state.list[archived ? "archived" : "all"];
  },
  users: (state) => {
    return state.users;
  },
  user: (state) => {
    return state.user;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
