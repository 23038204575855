import { newLogger } from "@/utils/util";
import Vue from "vue";
import StoreCore from "@/store/StoreCore";

let logger = newLogger("Role-Store");

const urls = {
  get: (id) => `/role/${id}`,
  all: "/role",
};

const initState = () => {
  return {
    roles: [],
    role: {},
  };
};

const storeCore = new StoreCore();
const actions = {
  fetch: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.all,
        dispatch,
        flair: "Loading roles...",
        force,
      });
      commit("saveRoles", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  get: async function ({ commit, dispatch }, { roleId, force } = {}) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.get(roleId),
        dispatch,
        flair: "Loading role...",
        force,
      });
      commit("saveRole", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveRole: (state, data) => {
    Vue.set(state, "role", data);
  },
  saveRoles: (state, data) => {
    Vue.set(state, "roles", data);
  },
};

const getters = {
  role: (state) => {
    return state.role;
  },
  roles: (state) => {
    return state.roles;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
