import { newLogger } from "@/utils/util";
import Vue from "vue";
import StoreCore from "@/store/StoreCore";

let logger = newLogger("ProcessingType-Store");

const urls = {
  get: (id) => `/processingType/${id}`,
  all: "/processingType",
  root: "/processingType/root",
  create: "/processingType",
  update: (id) => `/processingType/${id}`,
  delete: (id) => `/processingType/delete/${id}`,
};

const initState = () => {
  return {
    processingTypes: [],
    processingType: {},
  };
};

const storeCore = new StoreCore();
const actions = {
  fetch: async function ({ commit, dispatch }, { withDeleted, force } = {}) {
    try {
      const url = urls.all + (withDeleted ? "?withDeleted=true" : "");
      let data = await this.makeRequest({
        method: "get",
        url: url,
        dispatch,
        flair: "Loading processing types...",
        force,
      });
      commit("saveProcessingTypes", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  get: async function ({ commit, dispatch }, { processingTypeId, force } = {}) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.get(processingTypeId),
        dispatch,
        flair: "Loading processing type...",
        force,
      });
      commit("saveProcessingType", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchRoot: async function (
    { commit, dispatch },
    { withDeleted, force } = {}
  ) {
    try {
      const url = urls.root + (withDeleted ? "?withDeleted=true" : "");
      let data = await this.makeRequest({
        method: "get",
        url: url,
        dispatch,
        flair: "Loading processing types...",
        force,
      });
      commit("saveRootProcessingTypes", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  create: async function ({ commit, dispatch }, request) {
    try {
      await this.makeRequest({
        method: "post",
        url: urls.create,
        data: request,
        dispatch,
        flair: "Saving processing type...",
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  update: async function ({ commit, dispatch }, { id, request }) {
    try {
      await this.makeRequest({
        method: "put",
        url: urls.update(id),
        data: request,
        dispatch,
        flair: "Updating processing type...",
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  delete: async function ({ commit, dispatch }, id) {
    try {
      await this.makeRequest({
        method: "delete",
        url: urls.delete(id),
        dispatch,
        flair: "Deleting processing type...",
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveProcessingType: (state, data) => {
    Vue.set(state, "processingType", data);
  },
  saveProcessingTypes: (state, data) => {
    Vue.set(state, "processingTypes", data);
  },
  saveRootProcessingTypes: (state, data) => {
    Vue.set(state, "rootProcessingTypes", data);
  },
};

const getters = {
  processingType: (state) => {
    return state.processingType;
  },
  processingTypes: (state) => {
    return state.processingTypes;
  },
  rootProcessingTypes: (state) => {
    return state.rootProcessingTypes;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
