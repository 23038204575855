import { newLogger } from "@/utils/util";
import Vue from "vue";
import StoreCore from "@/store/StoreCore";

let logger = newLogger("Season-Store");

const urls = {
  base: "/season",
  withId: (id) => `/season/${id}`,
  forLocation: (locationId) => `/season/${locationId}/list`,
};

/**
 * Season management store
 */

const initState = () => {
  return {
    season: {},
    seasonsForLocation: {},
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchForLocation: async function (
    { commit, dispatch },
    { locationId, force } = {}
  ) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.forLocation(locationId),
        dispatch,
        flair: "Loading seasons for location...",
        force,
      });
      commit("saveLocationSeasons", { locationId, data });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),

  fetch: async function ({ commit, dispatch }, { id, force } = {}) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.withId(id),
        dispatch,
        flair: "Loading season...",
        force,
      });
      commit("saveSeason", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),

  create: async function ({ commit, dispatch }, data) {
    try {
      await this.makeRequest({
        method: "post",
        url: urls.base,
        data,
        dispatch,
        flair: "Creating season...",
        errorToast: false,
      });
      return Promise.resolve();
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      return Promise.reject((err.response && err.response.data.message) || err);
    }
  }.bind(storeCore),

  update: async function ({ commit, dispatch }, { id, data } = {}) {
    try {
      await this.makeRequest({
        method: "put",
        url: urls.withId(id),
        data,
        dispatch,
        flair: "Updating season...",
        errorToast: false,
      });
      return Promise.resolve();
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      return Promise.reject((err.response && err.response.data.message) || err);
    }
  }.bind(storeCore),

  archive: async function ({ commit, dispatch }, id) {
    try {
      await this.makeRequest({
        method: "delete",
        url: urls.withId(id),
        dispatch,
        flair: "Archiving season...",
        errorToast: false,
      });
      return Promise.resolve();
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      return Promise.reject((err.response && err.response.data.message) || err);
    }
  }.bind(storeCore),
  restore: async function ({ commit, dispatch }, id) {
    try {
      await this.makeRequest({
        method: "patch",
        url: urls.withId(id),
        dispatch,
        flair: "Restoring season...",
        errorToast: false,
      });
      return Promise.resolve();
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      return Promise.reject((err.response && err.response.data.message) || err);
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveLocationSeasons: (state, { locationId, data }) => {
    Vue.set(state.seasonsForLocation, locationId, data);
  },
  saveSeason: (state, data) => {
    Vue.set(state, "season", data);
  },
};

const getters = {
  seasonsForLocation: (state) => (locationId) => {
    return state.seasonsForLocation[locationId];
  },
  season: (state) => {
    return state.season;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
