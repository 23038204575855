import { newLogger } from "@/utils/util";
import Vue from "vue";
import StoreCore from "@/store/StoreCore";

let logger = newLogger("WeightUnit-Store");

const urls = {
  get: (label) => `/weightUnit/${label}`,
  all: "/weightUnit",
  delete: (id) => `/weightUnit/${id}`,
  create: "/weightUnit",
};

const initState = () => {
  return {
    weightUnits: [],
    weightUnit: {},
  };
};

const storeCore = new StoreCore();
const actions = {
  fetch: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.all,
        dispatch,
        flair: "Loading weightUnits...",
        force,
      });
      commit("saveWeightUnits", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  get: async function ({ commit, dispatch }, { weightUnitLabel, force } = {}) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.get(weightUnitLabel),
        dispatch,
        flair: "Loading Weight Unit...",
        force,
      });
      commit("saveWeightUnit", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  create: async function ({ commit, dispatch }, request) {
    try {
      await this.makeRequest({
        method: "post",
        url: urls.create,
        data: request,
        dispatch,
        flair: "Saving weightUnit...",
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  delete: async function ({ commit, dispatch }, id) {
    try {
      await this.makeRequest({
        method: "delete",
        url: urls.delete(id),
        dispatch,
        flair: "Deleting weightUnit...",
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveWeightUnit: (state, data) => {
    Vue.set(state, "weightUnit", data);
  },
  saveWeightUnits: (state, data) => {
    Vue.set(state, "weightUnits", data);
  },
};

const getters = {
  weightUnit: (state) => {
    return state.weightUnit;
  },
  weightUnits: (state) => {
    return state.weightUnits;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
