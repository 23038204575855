import Vue from "vue";

const eventBus = new Vue();
export default eventBus;

export const EVENTS = {
  OVERTIME_REQUESTED: "overtime-requested",
  PAGE_LOADING: "page-loading",
  PAGE_LOADED: "page-loaded",
  PROJECT_FILTER_CHANGE: "project-filter-change",
};

eventBus.emitPageLoading = function () {
  eventBus.$emit(EVENTS.PAGE_LOADING);
};

eventBus.emitPageLoaded = function () {
  eventBus.$emit(EVENTS.PAGE_LOADED);
};
