import { newLogger } from "@/utils/util";
import Vue from "vue";
import StoreCore from "@/store/StoreCore";

let logger = newLogger("Price-Store");

const urls = {
  todays: (locationId) => `/price/today/${locationId}`,
  latest: (locationId) => `/price/latest/${locationId}`,
  price: "/price",
  priceWithId: (id) => `/price/${id}`,
  yearlyPriceForLocation: (locationId) => `/price/history/yearly/${locationId}`,
  monthlyPriceForLocation: (locationId) =>
    `/price/history/monthly/${locationId}`,
};

/**
 * Description of the store
 */

const initState = () => {
  return {
    todays: [],
    latest: [],
    yearly: [],
    monthly: [],
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchTodays: async function (
    { commit, dispatch },
    { locationId, force } = {}
  ) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.todays(locationId),
        dispatch,
        flair: "Loading today's prices...",
        force,
      });
      commit("saveTodaysPrices", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),

  fetchLatest: async function (
    { commit, dispatch },
    { locationId, force } = {}
  ) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.latest(locationId),
        dispatch,
        flair: "Loading latest prices...",
        force,
      });
      commit("saveLatestPrices", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),

  fetchHistoryYearly: async function (
    { commit, dispatch },
    { locationId, force } = {}
  ) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.yearlyPriceForLocation(locationId),
        dispatch,
        flair: "Loading yearly prices...",
        force,
      });
      commit("saveYearlyPrices", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchHistoryMonthly: async function (
    { commit, dispatch },
    { locationId, force } = {}
  ) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.monthlyPriceForLocation(locationId),
        dispatch,
        flair: "Loading monthly prices...",
        force,
      });
      commit("saveMonthlyPrices", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),

  save: async function ({ commit, dispatch }, data) {
    try {
      await this.makeRequest({
        method: "post",
        url: urls.price,
        data,
        dispatch,
        flair: "Saving prices...",
        errorToast: false,
      });
      return Promise.resolve();
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      return Promise.reject((err.response && err.response.data.message) || err);
    }
  }.bind(storeCore),

  update: async function ({ commit, dispatch }, { id, data } = {}) {
    try {
      await this.makeRequest({
        method: "put",
        url: urls.priceWithId(id),
        data,
        dispatch,
        flair: "Updating prices...",
        errorToast: false,
      });
      return Promise.resolve();
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      return Promise.reject((err.response && err.response.data.message) || err);
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveTodaysPrices: (state, data) => {
    Vue.set(state, "todays", data);
  },
  saveLatestPrices: (state, data) => {
    Vue.set(state, "latest", data);
  },
  saveYearlyPrices: (state, data) => {
    Vue.set(state, "yearly", data);
  },
  saveMonthlyPrices: (state, data) => {
    Vue.set(state, "monthly", data);
  },
};

const getters = {
  todays: (state) => {
    return state.todays;
  },
  latest: (state) => {
    return state.latest;
  },
  yearly: (state) => {
    return state.yearly;
  },
  monthly: (state) => {
    return state.monthly;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
