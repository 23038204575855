import { newLogger } from "@/utils/util";
import Vue from "vue";
import StoreCore from "@/store/StoreCore";

let logger = newLogger("CherryQuality-Store");

const urls = {
  get: (id) => `/coffeeCherryQuality/${id}`,
  all: "/coffeeCherryQuality",
  delete: (id) => `/coffeeCherryQuality/${id}`,
  create: "/coffeeCherryQuality",
  update: (id) => `/coffeeCherryQuality/${id}`,
};

const initState = () => {
  return {
    cherryQualities: [],
    cherryQuality: {},
  };
};

const storeCore = new StoreCore();
const actions = {
  fetch: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.all,
        dispatch,
        flair: "Loading cherryQualities...",
        force,
      });
      commit("saveCherryQualities", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  get: async function (
    { commit, dispatch },
    { outputQualityId: cherryQualityId, force } = {}
  ) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.get(cherryQualityId),
        dispatch,
        flair: "Loading cherryQuality...",
        force,
      });
      commit("saveCherryQuality", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  create: async function ({ commit, dispatch }, request) {
    try {
      await this.makeRequest({
        method: "post",
        url: urls.create,
        data: request,
        dispatch,
        flair: "Saving cherryQuality...",
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  update: async function ({ commit, dispatch }, { id, request }) {
    try {
      await this.makeRequest({
        method: "put",
        url: urls.update(id),
        data: request,
        dispatch,
        flair: "Updating cherryQuality...",
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  delete: async function ({ commit, dispatch }, id) {
    try {
      await this.makeRequest({
        method: "delete",
        url: urls.delete(id),
        dispatch,
        flair: "Deleting cherryQuality...",
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveCherryQuality: (state, data) => {
    Vue.set(state, "cherryQuality", data);
  },
  saveCherryQualities: (state, data) => {
    Vue.set(state, "cherryQualities", data);
  },
};

const getters = {
  cherryQuality: (state) => {
    return state.cherryQuality;
  },
  cherryQualities: (state) => {
    return state.cherryQualities;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
